<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Top Deal Details</span>
      </div>
      <div class="vx-col w-full">
        <vs-input 
          class="w-full mt-4" 
          label="Titile" 
          :danger="validation.title?true:false"
          :danger-text="validation.title?validation.title[0]:''"
          :value="form.title"
          @input="updateForm({key: 'title', value: $event})" />
      </div>
      <div class="vx-col w-full md:w-3/3">
        <vs-input 
          class="w-full mt-4" 
          label="Sub Title" 
          :danger="validation.subtitle?true:false"
          :danger-text="validation.subtitle?validation.subtitle[0]:''"
          :value="form.subtitle"
          @input="updateForm({key: 'subtitle', value: $event})" />
      </div>
    <div class="vx-col w-full md:w-3/3 mt-4">
       <label>Type</label>
        <v-select 
            :danger="validation.type?true:false"
            :value="dropdown.type"
            @input="updateForm({key: 'type', value: $event})"
            :options="types" />
            <span class="text-danger text-sm" 
            v-show="validation.type?true:false">
        {{ validation.type?validation.type[0]:''}}
      </span>
  </div>
  </div>
  </div>
</template>

<script>

import vSelect,{vOption} from 'vue-select'
import { mapGetters,mapActions } from 'vuex'

export default {  
  components: {
    vSelect,
    vOption
  },

  computed: {
    ...mapGetters({
        form: 'topdeals/getFormObj',
        types: 'topdeals/getTopDealTypes',
        dropdown: 'topdeals/getDropdownObj',
        validation: 'validation/getValidationErrors',
    })
  },
  methods: {
    ...mapActions({
      updateForm: 'topdeals/updateForm',
    })
  }
}
</script>
