<template>
  <div id="page-user-list">
    
      <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
       <div class="vx-row mb-6">

        <div class="vx-col w-full md:w-2/3">
          <div class="vx-card p-6 mb-6">
            <TopDealForm></TopDealForm>
          
          </div>
        </div>
        <div class="vx-col w-full md:w-1/3">
            <div class="vx-card p-6 mb-6">
              <TopDealImage></TopDealImage>
            </div>
        </div>
       </div>

            <!-- Save & Reset Button -->
        <div class="vx-card p-4 mb-4">
            <div class="flex flex-wrap items-center justify-end">
              <vs-button button="submit" class="ml-auto" >Save Changes</vs-button>
            </div>
        </div>
      </form> 
	</div>
</template>

<script>
import vSelect from 'vue-select'
import TopDealForm from './common/TopDealForm';
import TopDealImage from './common/TopDealImage';
import {TOP_DEAL} from '../../../constant/entity-identifier';
import loaderMixin from '../../../mixins/loaderMixin';
import { mapGetters, mapActions } from 'vuex'

import {storeImage} from '../../../services/image';

export default {
  components: {
    vSelect,
    TopDealForm,
    TopDealImage
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        form: 'topdeals/getFormObj',
        topdeals: 'topdeals/getTopDeals',
        images: 'topdeals/getImages',
    })
  },
  created() {
      this.clearForm();
      this.clearValidationErrors();
      let thisRef = this;
      let params = new URLSearchParams();
      params.append("page", this.page);
      this.fetchAndSetTopDeals({query: params}).then((res) =>{
        if(thisRef.topdeals.pagination.total_records > 2){
          this.$vs.notify({ 
              title: 'Top Deal',
              text: 'Cannot create more than 3 top deals.',
              color: 'danger',
              position: 'top-right'
          });
          this.$router.push('/topdeals');
        }
      })
    },
  methods: {
    ...mapActions({
      createTopDeal: 'topdeals/createTopDeal',
      fetchAndSetTopDeals: 'topdeals/fetchAndSetTopDeals',
      clearForm: 'topdeals/clearForm',
      clearValidationErrors: 'validation/clearValidationErrors',
    }),
    prepareForm(id, type) {
      let formData = new FormData();
      formData.append("entity_id", id);
      formData.append("entity", type);
      formData.append("images[]", this.images[0]);
      formData.append("alt[]", 'icon');
      return formData;
    },
    submitForm() {
      this.openLoading();
      this.createTopDeal(this.form)
        .then(async response => {
           if(response.status === 201) {
             if(this.images.length > 0) {
                await storeImage(this.prepareForm(response.data.data.id, TOP_DEAL));
                this.closeLoading();
             }else{
                this.closeLoading();}
            this.$vs.notify({ 
                title: 'Top Deal Created',
                text: 'Top Deal created successfully.',
                color: 'success',
                position: 'top-right'
            });
            this.$router.push('/topdeals');
           }
        }).catch(() => this.closeLoading());
    },
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
